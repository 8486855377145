.o-footer {
    margin-top: var(--baselineX2);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--cMain);
    color: var(--cTextInvert);

    a {
        color: var(--cTextInvert);

        &:hover {
            color: var(--cAltL10);
        }
    }

    &_contact {
        padding-bottom: var(--base);
        text-align: center;
        font-style: normal;

        &-details {
            list-style: none;
        }

        @media screen and (min-width: $bpM) {
            &-details {
                li {
                    display: inline-block;

                    &::after {
                        content: " – ";
                    }

                    &:last-child::after {
                        content: "";
                    }
                }
            }
        }
    }
}
