.button {
    display: inline-block;
    color: var(--cTextInvert);
    background-color: var(--cMain);
    padding: var(--s40) var(--s10);
    border-radius: var(--roundDefault);
    text-decoration: none;
    margin-top: var(--base);

    &:hover {
        color: var(--cTextInvert);
        transform: scale(1.03);
	    transition: all 0.10s;
    }
}