html {
	font-family: var(--fMain);
	color: var(--cText);
	font-kerning: normal;
}

body {
	font-size: var(--base);
	line-height: var(--baseline);
}

h1,
h2,
h3,
h4 {
	letter-spacing: -0.01em;
	word-spacing: -0.08em;
}

h2 {
	font-size: var(--l40);
	line-height: var(--baselineMedium);
}
h3 {
	font-size: var(--l30);
}
h4 {
	font-size: var(--l20);
}

h5 {
	font-size: var(--base);
	margin-top: var(--baseline);
	font-weight: 700;
}

h6 {
	font-size: var(--s10);
	margin-top: var(--baseline);
	text-transform: uppercase;
	font-weight: 700;
}

p {
	margin-top: var(--baseline);
}

h1,
h2 {
	font-family: var(--fAlt);
	font-weight: 400;
	margin-bottom: 0;
	margin-top: var(--baselineX2);
}

h3,
h4,
h5,
h6 {
	font-family: var(--fMain);
	font-weight: 500;
	margin-bottom: 0;
	margin-top: var(--baselineMedium);
}

h4,
h5,
h6 {
	+ p {
		margin-top: var(--s50);
	}
}

// h1 {
// 	font-size: var(--l60);
// 	line-height: var(--baselineX2);

// 	a {
// 		color: var(--cText);
// 		text-decoration: none;
// 	}
//
// 	+ p,
// 	+ div {
// 		margin-top: var(--baselineX2);
// 	}
//  }

a {
	color: var(--cMain);

	&:hover {
		color: var(--cMainD10);
	}
}

sup {
	line-height: 1;

	.footnote-ref {
		font-size: var(--s10);
		text-decoration: none;
		padding: var(--thick);
		margin: -0.2em;

		&:hover {
			background: var(--cMain);
			color: var(--cBright);
		}
	}
}

pre {
	padding: var(--base);
}

p code {
	font-family: "Noto Sans Mono", monospace;
	font-size: var(--s20);
	background-color: var(--cText);
	color: white;
	padding: 1px 8px;
	border-radius: 5px;
	// box-sizing: border-box;
}

.footnotes {
	font-style: italic;
	font-size: var(--s10);

	hr {
		margin-top: var(--baseline);
		border: none;
		border-top: medium double #999;
		text-align: center;
	}
}
