.logo {
  margin-top: var(--base);
  max-width: 12em;

  img {
    display: block;
    height: 100%;
    width: 100%;
  };
}
