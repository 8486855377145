.m-movies {

  &_featured {
    margin-top: var(--baseline);
    display: flex; 
    flex-direction: column;
    background-color: lightgoldenrodyellow;
    padding: var(--base);

   @media screen and (min-width: 530px) {
     padding: var(--l50);
   }
   @media screen and (min-width: $bpM) {
    flex-direction: row;
   } 

    &-moviecover {
      box-shadow: var(--thin) var(--thin) 0 hsl(0,0%,70%);
      transition: all 0.10s;

      &:hover, &:active {
        box-shadow: var(--thick) var(--thick) 0 hsl(0,0%,60%);
        transform: scale(1.03);
      }
      
      @media screen and (min-width: $bpM) {
        min-width: 16em;
        margin-right: var(--baseline)
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      h2 {
        margin-top: var(--baseline);
      }
    }
  }

  &_list {
    margin-top: var(--baseline);
    display: flex;
    flex-direction: column;
    row-gap: 4vw;
    column-gap: 3%;
    align-items: center;

    @media screen and (min-width: $bpS) {
      row-gap: 2vw;
      flex-wrap: wrap;
      flex-direction: row;
    }

    &-item {
      width: 100%;
      box-shadow: var(--thin) var(--thin) 0 hsl(0,0%,70%);
      transition: all 0.10s;

      &:hover, &:active {
        box-shadow: var(--thick) var(--thick) 0 hsl(0,0%,60%);
        transform: scale(1.03);
      }

      @media screen and (min-width: $bpS) {
        width: 48%;
      }

      @media screen and (min-width: $bpM) {
        width: 31%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        
      }
    }
  }
}
